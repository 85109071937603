@import url('./fonts/font-face.css');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  transition: background 0.4s;
}

@media (min-width: 1024px) {
  html,
  a,
  label {
    cursor: none;
  }
}
