/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  src: local('Gilroy-Bold'), url('Gilroy-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 900;
  src: local('Gilroy-Heavy'), url('Gilroy-Heavy.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 300;
  src: local('Gilroy-Light'), url('Gilroy-Light.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  src: local('Gilroy-Medium'), url('Gilroy-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Regular'), url('Gilroy-Regular.woff') format('woff');
}
